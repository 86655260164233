<template>
    <v-navigation-drawer
      v-model="$store.state.drawer"
      absolute
      dark
      class="hidden-print-only body-1"
      id="va-drawer"
      color="secondary"
    >
      <v-list-item>
        <v-list-item-avatar>
          <!-- <v-img :src="$store.state.applogo"></v-img> -->
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title><v-img :src="$store.state.applogo" width="110px"></v-img></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      
      <v-list dense>  
      <v-list-item-group color="fff">
          <v-list-item
          v-for="(item, i) in MENUS"
          :key="i + '-main'"
          @click="$router.push(item.page).catch(err => {})"
        >
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item 
        @click="confirm = true"
        >
          <v-list-item-icon>
            <v-icon v-text="'mdi-power'"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="'Log Out'"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        
      </v-list-item-group>
    </v-list>

    <v-dialog
      v-model="confirm"
      persistent
      max-width="290">
      <v-card color="#232323" dark>
        <v-card-text class="pt-5">Continue to log out?</v-card-text>
        <v-card-actions>
         
          <v-btn
            color="warning darken-1"
            text
            @click="confirm = false">Cancel</v-btn>
             <v-spacer/>
          <v-btn
            color="green darken-1"
            text
            @click="confirm = false, logout()">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </v-navigation-drawer>

</template>
<script>
import {
  mapMutations
} from 'vuex'
  export default {
    data () {
      return {
        confirm: false,
        item: 1,
        mainmenu: [
            { title: 'MAIN', icon: 'mdi-view-dashboard', page:'/dashboard'},
            { title: "Side bets", icon: 'mdi-cash-multiple', page:'/events'},
           { title: "WALLET", icon: 'mdi-wallet', page:'/wallet'},
            { title: "Magic Number", icon: 'mdi-auto-fix', page:'/numbers'},
            { title: "Number TICKETS", icon: 'mdi-ticket', page:'/sales'},
          //  { title: "RESULTS", icon: 'mdi-format-list-checkbox', page:'/results'},
           //{ title: "SUMMARY", icon: 'mdi-clipboard-list', page:'/summary'},
            { title: "COMMISSIONS", icon: 'mdi-sack-percent', page:'/commissions'},
           { title: "MEMBERS", icon: 'mdi-account-multiple', page:'/players'},
         
           { title: "BUY CREDITS", icon: 'mdi-database-arrow-up', page:'/buy-credits'},
            { title: "CASH OUT", icon: 'mdi-cash', page:'/cashout'},
            { title: "MY ACCOUNT", icon: 'mdi-account', page:'/account'},
        ],
        playermenu: [
            { title: 'MAIN', icon: 'mdi-view-dashboard', page:'/dashboard'},
            { title: "Side bets", icon: 'mdi-cash-multiple', page:'/events'},
           { title: "WALLET", icon: 'mdi-wallet', page:'/wallet'},
           { title: "Magic Number", icon: 'mdi-auto-fix', page:'/numbers'},
            { title: "Number TICKETS", icon: 'mdi-ticket', page:'/sales'},
      
          //  { title: "RESULTS", icon: 'mdi-format-list-checkbox', page:'/results'},
            //{ title: "SUMMARY", icon: 'mdi-clipboard-list', page:'/summary'},
            { title: "MY ACCOUNT", icon: 'mdi-account', page:'/account'},
           { title: "BUY CREDITS", icon: 'mdi-database-arrow-up', page:'/buy-credits'},
           { title: "CASH OUT", icon: 'mdi-cash', page:'/cashout'},
           { title: "MY ACCOUNT", icon: 'mdi-account', page:'/account'},
        ],
        mini: true,
      }
    },
    computed: {
      MENUS() {
        return localStorage.user_role != "Player" ?  this.mainmenu : this.playermenu
       
      }
    },
    methods: {
      ...mapMutations(['setDrawer']),
      logout() {
      this.setDrawer(false)
      this.CLEAR_SESSION()
      this.$router.push('/login')
    }
    }
  }
</script>

